@import '../../../../common.scss';

.pageNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  border: solid 1px #000000;

  word-break: break-word;
  text-align: center;
  text-decoration: none;

  transition: $transition200ms;
  @include scalableButtons0_03;
}

.pageNav__yellow {
  background-color: $yellow;
  box-shadow: $squareBoxShadow;
}

@media(min-width: $minDesktopWidth) {
  .pageNav:not(.pageNav__yellow):hover {
    box-shadow: $squareBoxShadow;
  }

  .pageNav__yellow:hover {
    box-shadow: 7px 7px 0 0 #000;
  }
}

.website-background-red .pageNav__simple {
  background-color: $light_red;
}

.website-background-pink .pageNav__simple {
  background-color: $pink_dark;
}

.pageNav__red {
  background-color: rgb(235, 64, 52);
}

.pageNav__icon {
  width: 24px;
  height: 22px;
  margin-bottom: 5px;

  @include object-fit;
}

.pageNav__text {
  text-decoration: none;
  font-weight: 500;
  font-family: $serifFont;
  font-size: 15px;
  line-height: 1.33;

  transition: $transition200ms;
}

@media(max-width: $burgerMenuWidth) {
  .pageNav {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .pageNav__icon {
    position: relative;
    top: 2px;

    margin-right: 12px;
    
    @include object_fit;
    object-position: 50% 50%;
  }

  .pageNav__text {
    text-align: left;
  }
}
