@import './../../common.scss';

.footer__container--red-page {
  background-color: $red;
}

.footer__list {
  @include list_reset;
}

.footer__text {
  margin: 0;

  text-align: center;
  font-size: 12px;
}

.footer__text:not(:last-child) {
  margin: 0 0 7px 0;
}

.footer__link:visited {
  color: initial;
}

@media(max-width: $burgerMenuWidth) {
  .footer__container {
    margin-bottom: 50px;
  }

  .footer__container--donate {
    margin-bottom: 0;
  }

  .footer__text.footer__text--developed-by {
    margin-bottom: 20px;
  }
}

.testBTN,
.testUL {
  display: inline-block;
  
  margin: 0 auto;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Slab:wght@700&display=swap');

.testUL li {
  font-family: "Roboto Slab", serif;
  font-size: 30px;
}
