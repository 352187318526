@import './../../../common.scss';

.losses {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 80px 40px;

  @include list_reset;
}

.losses__item {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-basis: 280px;

  transition: ease-in-out 300ms;
}

@media(min-width: $minDesktopWidth) {
  .losses__item:hover {
    transform: scale(1.05);
  }
}



.losses__img-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 50%;
  padding: 0 15px 0 0;
}

.losses__img-container--thin {
  width: 35%;
}

/* color in a from of a filter generated here: https://codepen.io/sosuke/pen/Pjoqqp */
.losses__img {
  width: 80%;
  max-width: 100px;
  height: auto;

  @include object-fit;
  filter: invert(18%) sepia(3%) saturate(151%) hue-rotate(52deg) brightness(92%) contrast(90%);
}

.losses__img--thin {
  width: 80px;
}

.losses_img--helicopters,
.losses_img--tanks {
  max-width: 120px;
}

.losses__text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 50%;
}

.losses__text-container--wide {
  width: auto;
}

.losses__text-number-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  margin: 0 0 5px 0;
}

.losses__text-number {
  margin: 0 6px 0 0;

  text-align: left;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.27;
  color: #ffffff;
  font-family: $serifFont;
}

.losses__text-number-difference {
  position: relative;
  bottom: 3px;

  font-size: 20px;
  font-weight: normal;
  color: $yellow;
  font-family: $serifFont;
  font-weight: 400;
}

.losses__text-caption {
  margin: 0;
  padding: 0;
  width: 100%;

  text-align: left;
  word-break: break-word;
  font-size: 15px;
  line-height: 1.43;
  color: #000000;
}

@media(max-width: 1480px) {
  .losses {
    gap: 70px 10px;
  }
}

@media(max-width: 1370px) {
  .losses {
    justify-content: space-between;

    margin: 0 5%;
  }
}

@media(max-width: 1170px) {
  .losses__item {
    flex-basis: 220px;
  }

  .losses_img--helicopters,
  .losses_img--tanks {
    width: 100px;
  }

  .losses__text-number {
    font-size: 25px;
  }

  .losses__text-number-difference {
    font-size: 15px;
  }

  .losses__text-caption {
    font-size: 13px;
  }
}

@media(max-width: 855px) {
  .losses {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .losses__item {
    justify-content: center;
    align-items: center;
    flex-basis: auto;

    width: 350px;
  }

  .losses_img--helicopters,
  .losses_img--tanks {
    width: 120px;
  }

  .losses__text-number {
    font-size: 32px;
  }

  .losses__text-number-difference {
    font-size: 21px;
  }

  .losses__text-caption {
    font-size: 17px;
  }
}

@media(max-width: 834px) {
  .losses_img--helicopters,
  .losses_img--tanks {
    width: 90%;
  }
}

@media(max-width: 650px) {
  .losses__item {
    width: 100%;
  }
}
