@import '../../common.scss';

.screenshot__page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-height: 100vh;

  // overflow-x: scroll;
}

.screenshot__header {
  margin: 0 auto $header_margin_bottom50 auto;
  min-height: $headerMinHeight120;
}

.screenshot__header.standardHeader {
  margin: 0;
}

.screenshot__sizes-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;

  margin: 0 0 20px 0;
}

.screenshot__download-btn {
  margin: 0 0 60px 0;
  padding: 15px 25px;
  border: 1px solid #000000;

  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;

  background-color: $light_red;
  cursor: pointer;

  @include scalableButtons0_05;
  @include hoverSquareBoxShadow;
}

.screenshot__download-btn:disabled {
  color: #000000;
  text-transform: initial;

  background-color: $pink_dark;
  cursor: auto;
}

.screenshot__download-btn:hover:disabled {
  transform: scale(1);
  box-shadow: none;
}

.screenshot__download-btn:active:disabled {
  opacity: 1;
}

.screenshot__picture-container {
  position: relative;

  // display: flex;
  // justify-content: flex-start;
  align-self: flex-start;
  overflow-x: scroll;
  
  width: 100%;
  box-sizing: border-box;
}

.screenshot__picture-container--visible {
  display: block;
}

@media(max-width: 1021px) {
  .screenshot__header.standardHeader {
    margin: 40px;
  }
}

@media(max-width: 720px) {
  .screenshot__sizes-wrapper {
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media(max-width: 360px) {
  .screenshot__header.standardHeader {
    min-height: unset;
  }
}
