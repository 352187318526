@import '../../../common.scss';

.screenshot-sizes__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  margin: 0;
  padding: 10px 15px;
  border: 1px solid #000000;
  box-sizing: border-box;

  background-color: transparent;
  cursor: pointer;

  @include scalableButtons0_05;
  @include hoverSquareBoxShadow;
}

.screenshot-sizes__container--selected {
  background-color: $yellow;
  color: #000000;
}

.screenshot-sizes__img {
  width: 15px;
  height: 15px;
  @include object_fit;
}

.screesnshot-sizes__media-name,
.screesnshot-sizes__size {
  font-size: 13px;
}
