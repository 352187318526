@import './../../common.scss';

.charts__page-container {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-height: 100vh;
  padding: 0;
  box-sizing: border-box;
}

.charts__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;

  padding: 0 0 30px 0;
}

.charts__header {
  margin: 0 0 40px 0;
}

.charts__config {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 10px;

  @include list_reset;
  box-sizing: border-box;
}

.charts__canvas-wrapper {
  width: 100%;
}

.charts__canvas {
  max-width: 100% !important;
  
  background-color: transparent;
}

@media(max-width: 1150px) {
  .charts__container {
    gap: 30px;
  }
}

@media(max-width: 800px) {
  .charts__canvas-wrapper {
    height: 300px;
  }
}

@media(max-width: 545px) {
  .charts__config {
    margin: 0 auto;
    padding: 0;
  }
}

@media(max-width: 480px) {
  .charts__header {
    margin-bottom: 20px;
  }
}
