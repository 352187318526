@import '../../../common.scss';

.expandable-section__section {
  padding: 24px;
  border: 1px solid #000000;
}

.expandable-section__copyFields-container {
  height: 0;

  overflow: hidden;
  opacity: 0;
  transition: ease-in-out 450ms;
}

.expandable-section__copyFields-container--visible {
  height: auto;

  opacity: 1;
}

.expandable-section__header {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;

  background-color: transparent;
  cursor: pointer;
}

.expandable-section__header--expandable {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandable-section__sub-header-wrapper {
  display: block;
  width: 70%;
  margin: 0;

  text-align: left;
}

.expandable-section__move-btn {
  position: relative;

  width: 20px;
  height: 20px;
  padding: 0;
  border: none;

  background-color: transparent;
  @include scalableButtons0_1;

  transition: $transition200ms;
}

.expandable-section__move-btn::before,
.expandable-section__move-btn::after {
  content: "";

  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  width: 22px;
  height: 1px;

  transform: translate(-50%, -50%);
  
  background-color: #000000;

  transition: $transition200ms;
}

.expandable-section__expand-btn::after {
  transform: translate(-50%, -50%) rotate(-90deg);
}

@media(max-width: 500px) {
  .expandable-section__section {
    padding: 22px 20px;
  }
}
