@import './common.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
  
  font-family: $sansSerifFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-weight: 400;
}

body {
  margin: 0;
}

// Removes the blue highlight from the clicked / tapped elements
// https://stackoverflow.com/questions/45049873/how-to-remove-the-blue-highlight-of-button-on-mobile
div,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
