.rotate__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.rotate__img {
  width: 50px;
  height: 50px;

  object-fit: contain;
}

.rotate__text {
  margin: 0;
  padding: 5px 10px 0 10px;
  
  font-size: 14px;
  text-align: center;
}
