@import '../../../common.scss';

.chartModeButton__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.chartModeButton__list {
  @include list_reset;
}

.chartModeButton__header {
  margin: 0 0 10px 0;

  font-size: 18px;
  font-weight: 700;
}

.chartModeButton__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.chartModeButton__item:not(:last-child) {
  margin-bottom: 7px;
}

.chartModeButton__input {
  display: none;
}

.chartModeButton__custom-input {
  position: relative;
  
  display: inline-block;

  width: 25px;
  height: 25px;
  margin: 0 5px 0 0;
  border: 1px solid #000000;

  cursor: pointer;
}

.chartModeButton__custom-input::after {
  content: "";

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;
  width: 15px;
  height: 15px;
  
  background-color: #000000;
}

.chartModeButton__custom-input--selected::after {
  display: inline-block;
}

.chartModeButton__label {
  position: relative;

  display: inline-block;

  font-size: 18px;
  
  cursor: pointer;
}

@media(max-width: $burgerMenuWidth) {
  .chartModeButton__wrapper {
    align-self: flex-start;
  }
}

@media(max-width: 450px) {
  .chartModeButton__header,
  .chartModeButton__label {
    font-size: 16px;
  }
}


