@import './../../common.scss';

.burger__button {
  position: sticky;
  top: $websitePaddingTop40;

  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 40px;
  height: 40px;
  margin: 0;
  padding: 14px 11px;
  border: 1px solid #000000;

  background-color: transparent;
  
  cursor: pointer;
}

.burger__button::before,
.burger__button::after {
  content: "";

  display: block;

  width: 13px;
  height: 1px;

  background-color: #000000;
}

@media(max-width: 650px) {
  .burger__button {
    top: $websitePaddingTop34;
  }
}

@media(max-width: $burgerMenuWidth) {
  .burger__button {
    display: flex;
  }
}

@media(max-width: $stopBurgerScrollWidth) {
  .burger__button {
    position: absolute;
    top: 0;
  }
}
