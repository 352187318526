@import './../../../common.scss';

.lang__wrapper {
  position: relative;

  width: 77px;
  height: 40px;
  padding: 5px;
  border: solid 1px #000;
  
  z-index: 1;
  cursor: pointer;
}

.lang__square {
  position: absolute;
  top: 50%;

  display: inline-block;

  width: 30px;
  height: 30px;
  border: solid 1px #000000;
  
  background-color: $yellow;
  
  transition: ease-in-out 400ms;
}

.lang__wrapper--circle-left .lang__square {
  transform: translateY(-50%);
}

.lang__wrapper--circle-right .lang__square {
  transform: translate(122%, -50%);
}

.lang__text {
  position: absolute;
  top: 50%;
  
  font-family: $serifFont;
  font-size: 14px;

  // It is 56 and not 50 because the "en"/"ua" did not look like
  // they were positioned right in the middle of the box
  transform: translateY(-56%);
  transition: ease-in-out 500ms;
}

.lang__text--eng {
  left: 12px;
}

.lang__text--ua {
  right: 10px;
}
