@import './common.scss';

:root {
  font-size: 16px;
}

.visually-hidden {
  position: absolute;
  
  width: 1px;
  height: 1px;
  border: 0;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
}

.website-background-red {
  background-color: $red;
}

.website-background-pink {
  background-color: $pink_light;
}

.website__grid {
  position: relative;

  display: grid;
  grid-template-columns: calc(140px + 70px) 1fr calc(140px + 70px);
  grid-template-rows: 1fr;
  grid-template-areas: "navigation main logo";

  max-width: 1440px;
  margin: 0 auto;
  padding: $websitePaddingTop40 $websitePaddingLeft40;
}

.website__grid-left {
  position: relative;

  grid-area: navigation;

  padding: 0 70px 0 0;
}

.website__fake-element {
  height: 1px;
  width: 100%;

  opacity: 0;
}

.website__grid-center {
  grid-area: main;
}

/*
* For infographics preview not to ruin the page layout when the opened page is screenshots page
* Not the best realization. Probably to be refactored in the future
*/
.website__grid-center--overflow-hidden {
  overflow-x: scroll;
}

.website__grid-right {
  grid-area: logo;

  padding: 0 0 0 70px;
}

@media(max-width: 1320px) {
  .website__grid {
    grid-template-columns: calc(140px + 40px) 1fr calc(140px + 40px);
  }

  .website__grid-left {
    padding: 0 40px 0 0;
  }

  .website__grid-right {
    padding: 0 0 0 40px;
  }
}

// 45px are provided instead of 40px for burger menu to have additional padding while scrolling
@media(max-width: 650px) {
  .website__grid {
    // grid-template-columns: 55px 1fr 55px;
    
    padding: $websitePaddingTop34 $websitePaddingLeft20;
  }

  .website__grid-right {
    padding: 0 0 0 20px;
  }
}

@media(max-width: $burgerMenuWidth) {
  .website__grid {
    grid-template-columns: 110px 1fr 110px;
    grid-template-rows: 40px 1fr;
    grid-template-areas: "navigation . logo"
                         "navigation main logo";
    width: 100%;
  }

  .website__grid-left {
    padding: 0;
  }

  .website__grid-right {
    justify-self: end;
    padding: 0 0 0 20px;
  }
}

@media(max-width: $stopBurgerScrollWidth) {
  .website__grid {
    grid-template-columns: 40px 1fr 50px 40px;
    grid-template-areas: "navigation . logo logo"
                         "main main main main";
  }

  .website__grid-left,
  .website__grid-right {
    padding: 0;
  }

  .website__fake-element {
    display: none;
  }
}

@media(max-width: 500px) {
  .website__wrapper {
    padding: 30px 20px;
  }
}
