// ----------------- 1024 * 512 Twitter -----------------

.screenshot__picture-container_1024_512 {
  .dateList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 30px;
    margin: 0 10px 10px;
  }

  .dateList__item {
    width: 110px;
    margin: 0;
    padding: 5px;
  }

  .dateList__item--start {
    order: 1;
  }

  .dateList__item--days {
    order: 2;
  }

  .dateList__item--end {
    order: 3;
  }

  .dateList__important-text {
    margin: 0 0 3px 0;

    font-size: 8px;
  }

  .dateList__important-text--bigger {
    margin: 0 0 1px 0;

    font-size: 11px;
  }

  .dateList__caption {
    font-size: 7px;
  }
}

// ----------------- 1080 * 1920 Facebook stories -----------------

.screenshot__picture-container_1080_1920 {
  .dateList {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0 20px 40px;
    gap: 20px 20px;
  }

  .dateList__item {
    width: 129px;
    margin: 0;
    padding: 12px;
  }

  .dateList__important-text--bigger {
    margin: 0 0 3px 0;
    font-size: 20px;
  }

  .dateList__important-text {
    font-size: 13px;
    line-height: 1.3;
  }

  .dateList__important-text--bigger,
  .dateList__important-text {
    margin-bottom: 5px;
  }

  .dateList__caption {
    font-size: 9px;
  }
}

// ----------------- 1080 * 1080 Instagram -----------------

.screenshot__picture-container_1080_1080 {
  .dateList {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 0 20px 20px;
    gap: 20px 20px;
  }

  .dateList__item {
    width: 136px;
    padding: 17px;
    margin: 0;
  }

  .dateList__important-text--bigger {
    margin: 0 0 3px 0;
    font-size: 17px;
  }

  .dateList__important-text {
    margin-bottom: 5px;

    font-size: 11px;
    line-height: 1.3;
  }

  .dateList__caption {
    font-size: 8px;
  }
}

// ----------------- 940 * 788 Facebook -----------------

.screenshot__picture-container_940_788 {
  .dateList {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 0 10px 15px;
    gap: 22px;
  }

  .dateList__item {
    width: 120px;
    padding: 5px;
    margin: 0;
    border: 1px solid #000000;
  }

  .dateList__important-text--bigger {
    margin: 0 0 3px 0;
    font-size: 15px;
  }

  .dateList__important-text {
    margin-bottom: 5px;
    
    font-size: 10px;
    line-height: 1.3;
  }

  .dateList__caption {
    font-size: 7px;
  }
}
