@import '../common.scss';

// ----------------- 1024 * 512 Twitter -----------------

.screenshot__picture-container_1024_512 {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 0 10px 0;
    min-height: 0;
  }

  .header__header {
    max-width: 45%;
    margin: 0 auto 7px auto;
    padding: 0;

    font-family: $serifFont;
    font-size: 13px;
    line-height: 1;
  }

  p.header__text {
    margin: 0;
    font-size: 6px;
  }

  .header__update {
    display: none;
  }

  .header__update-date {
    font-style: normal;
  }
  
  .header__source-link {
    text-decoration: none;
    font-weight: 600;
  }

  .header__twitter-icon {
    margin: 0 0 0 5px;
  }

  .header__twitter-icon {
    height: 5px;
  }

  .header__source::after {
    content: "@GeneralStaffUA";
    display: inline-block;
    font-size: 6px;
  }
}

// ----------------- 1080 * 1920 Facebook stories -----------------

.screenshot__picture-container_1080_1920 {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 0 30px 0;
    min-height: 0;
  }

  .header__header {
    margin: 0 auto 10px auto;
    padding: 0;
    font-size: 28px;
    max-width: 80%;
  }

  p.header__text {
    margin: 0 auto;
    max-width: 80%;

    font-size: 12px;
  }

  p.header__text:not(:last-child) {
    margin-bottom: 5px;
  }

  .header__update-date {
    font-style: normal;
  }
  
  .header__source-link {
    text-decoration: none;
    font-weight: 600;
  }

  .header__twitter-icon {
    margin: 0 0 0 5px;
  }

  .header__twitter-icon {
    height: 7px;
  }

  .header__source::after {
    content: "@GeneralStaffUA";
    display: inline-block;
    font-size: 10px;
  }
}

// ----------------- 1080 * 1080 Instagram -----------------

.screenshot__picture-container_1080_1080 {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 0 20px 0;
    min-height: 0;
  }

  .header__header {
    margin: 0 auto 5px auto;
    padding: 0;
    font-size: 25px;
    max-width: 60%;
  }

  p.header__text {
    margin: 0;
    font-size: 8px;
  }

  p.header__text:not(:last-child) {
    margin-bottom: 2px;
  }

  .header__twitter-icon {
    height: 5px;
  }

  .header__source::after {
    content: "@GeneralStaffUA";
    display: inline-block;
    font-size: 6px;
  }

  .header__update-date {
    font-style: normal;
  }
  
  .header__source-link {
    text-decoration: none;
    font-weight: 600;
  }

  .header__twitter-icon {
    margin: 0 0 0 5px;
  }
}

// ----------------- 940 * 788 Facebook -----------------

.screenshot__picture-container_940_788 {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 0 15px 0;
    min-height: 0;
  }

  .header__header {
    margin: 0 auto 5px auto;
    padding: 0;
    font-size: 16px;
    max-width: 55%;
  }

  p.header__text {
    margin: 0;

    font-size: 6px;
  }

  p.header__text:not(:last-child) {
    margin-bottom: 2px;
  }

  .header__twitter-icon {
    height: 5px;
  }

  .header__source::after {
    content: "@GeneralStaffUA";
    display: inline-block;
    font-size: 6px;
  }

  .header__update-date {
    font-style: normal;
  }
  
  .header__source-link {
    text-decoration: none;
    font-weight: 600;
  }

  .header__twitter-icon {
    margin: 0 0 0 5px;
  }
}
