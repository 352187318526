@import './../../common.scss';

.navigation-wrapper {
  position: sticky;
  top: $websitePaddingTop40;
  z-index: 4;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  width: 140px;

  transition: ease-in-out 300ms;
}

.navigation-wrapper__top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-bottom: 80px;
}

.navigation-wrapper__close-btn {
  position: relative;

  display: none;

  width: 35px;
  height: 35px;
  border: none;

  background-color: transparent;
  cursor: pointer;
}

.navigation-wrapper__close-btn::before,
.navigation-wrapper__close-btn::after {
  content: "";

  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  width: 140%;
  height: 1px;

  background-color: #000000;
}

.navigation-wrapper__close-btn::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.navigation-wrapper__close-btn::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media(max-width: $burgerMenuWidth) {
  .website__grid-left {
    // position: static;
  }

  .navigation-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    align-items: flex-end;

    width: auto;
    padding: $websitePaddingTop40 $websitePaddingLeft40;

    background-color: $red;
    opacity: 0;
    transition: $transition200ms; 
    transform: scale(0);
  }

  .navigation-wrapper--opened {
    grid-area:  unset;

    opacity: 1;
    transform: scale(1); 
  }

  .navigation-wrapper .navigation-wrapper__top-container {
    margin-bottom: auto;
  }

  .navigation-wrapper__close-btn {
    display: block;
  }
}

@media(max-width: 370px) {
  .navigation-wrapper.navigation-wrapper--opened .pageNav {
    width: 100%;
  }
}
