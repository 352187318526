@import './../../../common.scss';

.copyField__container {
  display: flex;
  flex-direction: column;
  align-items: start;
  
  margin: 0 0 24px 0;
}

.copyField__label {
  margin: 0 0 8px 0;

  word-wrap: break-word;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
}

.copyField__input-wrapper {
  position: relative;

  display: flex;

  width: 100%;
  margin: 0;
}

.copyField__input {
  position: relative;

  flex-grow: 1;
  
  margin: 0;
  padding: 14px 63px 14px 12px;
  
  background-color: #f1eaea;
  font-size: 14px;
  line-height: 1.43;
  resize: none;
  word-break: break-word;
}

.copyField__copyButton {
  position: absolute;
  top: 50%;
  right: 10px;

  width: 20px;
  height: 20px;
  padding: 0;
  border: none;

  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: ease-out 200ms;
  transform: translateY(-50%);
}

.copyField__copyIcon {
  width: 100%;
  height: auto;

  @include scalableButtons0_1;

  transition: $transition200ms;
}

.copyField__copyIcon path {
  fill: rgb(0, 0, 0);
}

@media(max-width: 800px) {
  .copyField__input-wrapper {
    width: 100%;
  }

  .copyField__label {
    width: auto;
    margin: 0 0 5px 0;
  }
}
