// ----------------- 1024 * 512 Twitter -----------------

.screenshot__picture-container_1024_512 {
  .losses {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px 4px;

    margin: 0 5%;
  }

  .losses__item {
    flex-basis: 90px;
  }

  .losses__item:hover {
    transform: scale(1);
  }

  .losses__img-container {
    padding-right: 5px;
  }

  .losses__img {
    width: 70%;
  }

  .losses__img--thin {
    width: 110%;
  }

  .losses__text-number-container {
    margin: 0;
  }

  .losses__text-number {
    margin: 0 3px 0 0;

    font-size: 11px;
  }

  .losses__text-number-difference {
    bottom: 2px;

    font-size: 7px;
  }

  .losses__text-caption {
    font-size: 5px;
  }
}

// ----------------- 1080 * 1920 Facebook stories -----------------

.screenshot__picture-container_1080_1920 {
  .losses {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px 15px;

    margin: 0 5%;
  }

  .losses__item {
    flex-basis: 200px;
  }

  .losses__item:hover {
    transform: scale(1);
  }

  .losses__img-container {
    padding-right: 10px;
  }

  .losses__img {
    width: 70%;
  }

  .losses__img--thin {
    width: 85%;
  }

  .losses__text-number {
    margin: 0 5px 0 0;

    font-size: 22px;
  }

  .losses__text-number-difference {
    bottom: 6px;
    font-size: 12px;
  }

  .losses__text-caption {
    font-size: 10px;
  }
}

// ----------------- 1080 * 1080 Instagram -----------------

.screenshot__picture-container_1080_1080 {
  .losses {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px 30px;
    
    margin: 0 5%;
    border-bottom: none;
  }
  
  .losses__item {
    flex-basis: 125px;
  }
  
  .losses__item:hover {
    transform: scale(1);
  }

  .losses__img-container {
    padding-right: 7px;
  }
  
  .losses__img {
    width: 70%;
  }

  .losses__img--thin {
    width: 110%;
  }

  .losses__text-number {
    margin: 0 4px 0 0;

    font-size: 15px;
  }

  .losses__text-number-difference {
    bottom: 3px;
    font-size: 10px;
  }

  .losses__text-caption {
    font-size: 7px;
  }
}

// ----------------- 940 * 788 Facebook -----------------

.screenshot__picture-container_940_788 {
  .losses {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px 20px;
    
    margin: 0 5%;
  }

  .losses__item {
    flex-basis: 112px;
  }

  .losses__item:hover {
    transform: scale(1);
  }

  .losses__img {
    width: 70%;
  }

  .losses__img--thin {
    width: 105%;
  }

  .losses__img-container {
    padding-right: 7px;
  }

  .losses__text-number {
    margin: 0 3px 0 0;

    font-size: 13px;
  }

  .losses__text-number-container {
    margin-bottom: 2px;
  }

  .losses__text-number-difference {
    bottom: 3px;
    font-size: 8px;
  }

  .losses__text-caption {
    font-size: 6px;
  }
}
