@import './../../../common.scss';

.dateList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "start days end";
  gap: 20px;

  @include list_reset;
  margin-bottom: 80px;
}

.dateList__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 30px 50px;
  border: solid 1px #000;

  word-break: break-word;
  text-align: center;
  
  background-color: $light_red;
}

.dateList__item:not(:last-child) {
  margin-right: calc((100% - (282px * 3)) / 2);
}

.dateList__item--start {
  grid-area: start;
}

.dateList__item--days {
  grid-area: days;
}

.dateList__item--end {
  grid-area: end;

  background-color: $pink_dark;
}

.dateList__important-text,
.dateList__important-text--bigger,
.dateList__caption {
  margin: 0;
}

.dateList__item--end .dateList__important-text {
  color: #000000;
}

.dateList__important-text,
.dateList__important-text--bigger {
  margin-bottom: 15px;

  font-size: 21px;
  font-weight: 700;
  font-family: $serifFont;
  line-height: 1.2;
}

.dateList__important-text {
  font-size: 21px;
}

.dateList__important-text--bigger {
  font-size: 34px;
}

.dateList__caption {
  font-size: 15px;
}

@media(max-width: 1380px) {
  .dateList {
    gap: 20px 20px;
  }

  .dateList__item:not(:last-child) {
    margin-right: 0;
  }
}

@media(max-width: 1285px) {
  .dateList {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-template-areas: ". start start end end ."
                         ". . days days . .";
    gap: 20px;
  }
}

@media(max-width: 1170px) {
  .dateList {
    padding: 0;
  }
}

@media(max-width: 1080px) {
  .dateList {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-areas: "start"
                         "end"
                         "days";
    justify-items: center;
    align-items: stretch;
  }

  .dateList__item {
    width: 80%;
  }
}

@media(max-width: 440px) {

  .dateList__item {
    width: 70%;
  }
}
