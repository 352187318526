@import './../../../common.scss';

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: $header_margin_bottom50;
  padding: 0;
  
  text-align: center;
}

.header__header {
  margin: 0 0 17px 0;
}

.header__text {
  display: inline-block;

  margin: 0;

  font-size: 14px;
}

p.header__text:not(:last-child) {
  margin: 0 0 5px 0;
}

.header__update-date {
  font-style: italic;
  font-weight: 600;
}

.header__source-link:link,
.header__source-link:visited {
  color: #000000;
}

.header__twitter-icon {
  display: inline-block;
  
  margin: 0 0 0 5px;
  width: 10px;
  height: 10px;
}

@media(min-width: $minDesktopWidth) {
  .header__source-link:hover + .header__twitter-icon {
    fill: $yellow;
  }
}


