@import '../../../common.scss';

.regular-section {
  display: flex;
  align-items: center;

  padding: 22px 25px;
  border: 1px solid #000000;

  text-decoration: none;
}

.regular-section--has-link {
  transition: $transition200ms;
}

@media(min-width: $minDesktopWidth) {
  .regular-section--has-link:hover {
    @include hoverSquareBoxShadow;
  }
}

.regular-section__text-wrapper {
  width: 75%;
  padding: 0 15px 0 0;
}

.regular-section__header {
  margin: 0 0 10px 0;
}

.regular-section__paragraph {
  margin: 0 0 14px 0;

  font-size: 14px;
  line-height: 1.43;
}

.regular-section__image-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 25%;
}

.regular-section__image {
  max-height: 25px;
  max-width: 70px;
  
  @include object-fit;
}

@media(max-width: 500px) {
  .regular-section {
    padding: 22px 20px;
  }
}

