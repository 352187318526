@import './../../common.scss';

.main__page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  min-height: 100vh;
  
  background-color: $red;
  color: #ffffff;
}
