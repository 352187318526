@import '../../common.scss';

.logo__wrapper {
  position: sticky;
  top: $websitePaddingTop40;

  margin: 0;
  width: 100%;
}

.logo__image {
  width: 100%;
  height: auto;
}

@media(max-width: 650px) {
  .logo__wrapper {
    top: $websitePaddingTop34;
  }
}

@media(max-width: $burgerMenuWidth) {
  .logo__wrapper {
    // width: calc(100% - 20px);
    width: auto;
  }

  .logo__image {
    width: 100%;
  }
}
