$yellow: #f8fc74;
$red: #fd625c;
$light_red: #ff736d;
$pink_light: #fcf7f7;
$pink_dark: #ffd4d3;

$sansSerifFont: "Roboto", "Segoe UI", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;
$serifFont: "Roboto Slab", "Times", "Times New Roman", "Palatino", "Georgia", serif;

// Square shadow that all buttons shall have
$squareBoxShadow: 3px 3px 0 0 #000;
// Transition common for all transition-able elements on the screen
$transition200ms: ease-in-out 200ms;

// The min width of a user device screen for this device to be deemed as a desktop device
$minDesktopWidth: 1024px;
// The width of the screen when a burger menu shall be shown instead of navigation buttons
$burgerMenuWidth: 950px;
// The width of the screen when a burger menu shall not be sticky anymore and shall be fixed in the left top corner
$stopBurgerScrollWidth: 600px;

// Website padding sets that shall be applied for big and small screens
$websitePaddingTop40: 40px;
$websitePaddingLeft40: 40px;

$websitePaddingTop34: 34px;
$websitePaddingLeft20: 20px;

$header_margin_bottom50: 50px;

$headerMinHeight120: 120px;

$footerBottomGap100: 100px;

// The way buttons react to being hovered and pressed
@mixin scalableButtons0_1 {
  @media(min-width: $minDesktopWidth) {
    &:hover {
      transform: scale(1.1);
    }
  }

  &:active {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

@mixin scalableButtons0_05 {
  @media(min-width: $minDesktopWidth) {
    &:hover {
      transform: scale(1.05);
    }
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.8;
  }
}

@mixin scalableButtons0_03 {
  @media(min-width: $minDesktopWidth) {
    &:hover {
      transform: scale(1.03);
    }
  }

  &:active {
    transform: scale(0.97);
    opacity: 0.8;
  }
}

// Elements get a shadow when hovered
@mixin hoverSquareBoxShadow {
  transition: $transition200ms;

  @media(min-width: $minDesktopWidth) {
    &:hover {
      box-shadow: $squareBoxShadow;
    }
  }
}

@mixin object_fit {
  object-fit: contain;
  object-position: center;
}

// Resets ul-tags
@mixin list_reset {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Standard link: black font when not active and yellow background when hovered
.standardLink:link,
.standardLink:visited {
  color: #000000;
  font-weight: bold;
}

@media(min-width: $minDesktopWidth) {
  .standardLink:hover {
    background-color: $yellow;
  }
}

// Standard header, main header on each webpage except for donate webpage
.standardHeader {
  max-width: 575px;
  padding: 23px 0 0 0;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  font-family: "Roboto Slab";
  font-weight: 700;
}

@media(max-width: 950px) {
  .standardHeader {
    padding: 100px 0 0 0;
  }
}

@media (max-width: 545px) {
  .standardHeader {
    font-size: 32px;
  }
}

@media(max-width: 480px) {
  .standardHeader {
    padding-top: 80px;
  }
}

@media (max-width: 360px) {
  .standardHeader {
    font-size: 28px;
  }
}

// Provides a page container for all website pages. It has a padding allowing
// the page navigation to look good and not to be overlapped by other site components
.page-container {
  margin: 0 0 140px 0;
}

@media(max-width: $burgerMenuWidth) {
  .page-container {
    margin: 0 0 $footerBottomGap100 0;
  }
}

@media(max-width: 585px) {
  .page-container {
    margin: 0 30px $footerBottomGap100 30px;
  }
}

@media(max-width: 440px) {
  .page-container {
    margin: 0 0 $footerBottomGap100 0;
  }
}
