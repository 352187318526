// Don`t remove this import otherwise the html-to-image libriary won`t work well.
// Right fonts won`t be loaded to the final image =)
.screenshot__picture-container {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Roboto+Slab:wght@500;700&display=swap");
  box-sizing: border-box;
}

// padding for the whole picture depending on the specific infographic

// ----------------- 1024 * 512 Twitter -----------------

.screenshot__picture-container.screenshot__picture-container_1024_512 .scrollable {
  position: relative;

  padding: 15px;
}

// ----------------- 1080 * 1920 Facebook stories -----------------

.screenshot__picture-container.screenshot__picture-container_1080_1920 .scrollable {
  position: relative;

  padding: 45px 35px;
}

// ----------------- 1080 * 1080 Instagram -----------------

.screenshot__picture-container.screenshot__picture-container_1080_1080 .scrollable {
  position: relative;

  padding: 22px;
}

// ----------------- 940 * 788 Facebook -----------------

.screenshot__picture-container.screenshot__picture-container_940_788 .scrollable {
  position: relative;

  padding: 20px;
}

