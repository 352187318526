@import './../../common.scss';

.donate-bottom-button {
  display: none;
}

@media(max-width: $burgerMenuWidth) {
  .donate-bottom-button {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 20px 58px;
    border: 1px solid #000000;

    background-color: $yellow;
    text-decoration: none;
  }

  .donate-bottom-button__icon {
    width: 18px;
    height: 18px;
    margin: 0 9px 0 0;
    @include object-fit;
  }

  .donate-bottom-button__text {
    font-family: $serifFont;
    font-size: 17px;
    font-weight: 500;
  }
}