// ----------------- 1024 * 512 Twitter -----------------

.screenshot__picture-container_1024_512 {
  .logo__wrapper {
    position: absolute;

    width: 50px;
    top: 15px;
    left: unset;
    right: 15px;
  }

  .logo__image {
    width: 100%;
  }
}

// ----------------- 1080 * 1920 Facebook stories -----------------

.screenshot__picture-container_1080_1920 {
  .logo__wrapper {
    position: absolute;
    
    width: 70px;
    top: 15px;
    left: unset;
    right: 15px;
  }

  .logo__image {
    width: 100%;
  }
}

// ----------------- 1080 * 1080 Instagram -----------------

.screenshot__picture-container_1080_1080 {
  .logo__wrapper {
    position: absolute;
    
    width: 60px;
    top: 15px;
    left: unset;
    right: 15px;
  }

  .logo__image {
    width: 100%;
  }
}

// ----------------- 940 * 788 Facebook -----------------

.screenshot__picture-container_940_788 {
    .logo__wrapper {
    position: absolute;
    
    width: 50px;
    top: 10px;
    left: unset;
    right: 10px;
  }

  .logo__image {
    width: 100%;
  }
}
