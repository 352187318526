@import './../../common.scss';

.donate__page-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  min-height: 100vh;
  padding: 0;
}

.donate__article--military,
.donate__article--civil {
  margin: 75px 0 0 0;
  width: 47%;
}

.donate__header {
  max-width: 75%;
  margin: 0 0 50px 0;

  font-weight: 700;
  font-size: 28px;
  line-height: 1.46;
  font-family: $serifFont;
}

.donate__list {
  @include list_reset;

  list-style-position: inside;
}

.donate__country-header {
  margin: 0 0 32px 0;

  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  line-height: 1;
}

.donate__block + .donate__country-header {
  margin-top: 40px;
}

.donate__block--cda-contacts {
  margin-top: 73px;
}

.donate__item:not(:last-child) {
  margin-bottom: 73px;
}

.donate__organization-header {
  position: relative;

  display: inline-block;

  margin: 0 0 52px 0;
}

.donate__organization-header-text {
  position: relative;
  z-index: 2;

  font-family: $serifFont;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.36;
  word-wrap: break-word;

  box-shadow: inset 0 0 0 1000px $yellow, 0 0 0 5px $yellow;
}

.donate__block:not(:last-child) {
  margin-bottom: 20px;
}

.donate-section__header {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.33;
}

.donate__paragraph {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 0 0 32px 0;
}

.donate__strong {
  margin-bottom: 22px;

  font-size: 16px;
  font-weight: bold;
}

.donate__text {
  font-size: 16px;
  line-height: 1.38;
}

.donate__text:not(:last-child) {
  margin-bottom: 5px;
}

.donate__tel-number {
  text-decoration: none;
}

.donate__link {
  font-size: 18px;
  line-height: 1.33;
  text-decoration: underline;

  background-color: $yellow;
}

@media(max-width: 1170px) {
  .donate__page-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .donate__article--military,
  .donate__article--civil {
    width: 80%;
  }
}

@media(max-width: 520px) {
  .donate__article--military,
  .donate__article--civil {
    width: 100%;
  }
}
